<template>
  <div class="cy_contactUs">
    <div class="cy_contactUs_bg" v-if="Object.keys(basicData).length != 0">
      <img :src="basicData.basicImg" alt="" style="width:100%" />
    </div>
    <ul class="cy_contactUs_head">
      <li
        v-for="(item, idx) in basicData.basicList"
        :key="idx"
        :class="[active == idx ? 'li_on' : '']"
        @click="active = idx"
      >
        {{ item.value }}
      </li>
    </ul>
    <div class="cy_contactUs_nav">
      <ul class="nav_lxwm_list">
        <li v-for="(item, idx) in lxwmData.lxwmList" :key="idx">
          <div class="li_img">
            <img
              :src="lxwmData.lxwmImg[idx]"
              alt=""
              style="width:100%;height:100%"
            />
          </div>
          <h4 class="nav_lxwm_title">{{ item.key }}</h4>
          <p v-html="item.value"></p>
        </li>
      </ul>
      <div class="nav_lxwm_dz">
        <img :src="lxwmData.lxwmDz" alt="" style="width:100%">
      </div>
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  computed: {},
  data () {
    return {
      active: 0,
      basicData: {},
      lxwmData: {}
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          console.log(res.data.data)
          that.basicData = res.data.data.basic
          that.lxwmData = res.data.data.联系我们
        }
      })
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.cy_contactUs {
  width: 100%;
  height: 100%;
  color: #212529;
  .cy_contactUs_head {
    width: 100%;
    border-bottom: 1px solid #d0d0d0;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 20px;
      font-size: 18px;
      padding: 30px 0;
      box-sizing: border-box;
      cursor: pointer;
    }
    .li_on {
      border-bottom: 3px solid #c8151d;
      font-weight: bold;
    }
  }
  .cy_contactUs_nav {
    max-width: 1200px;
    margin: 80px auto;
    .nav_lxwm_title {
      font-size: 32px;
      text-align: center;
      margin: 50px 0 80px 0;
    }
    .nav_lxwm_title:after {
      content: '';
      width: 30px;
      height: 4px;
      display: block;
      margin: 0 auto;
      background: #fabe0d;
      margin-top: 10px;
    }
    .nav_lxwm_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 30%;
        box-sizing: border-box;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        padding: 0 25px;
        height: 500px;
        font-size: 14px;
        color: #555;
        .li_img {
          width: 135px;
          margin: 70px auto 45px auto;
        }
        h4 {
          font-size: 18px;
          text-align: center;
          color: #191919;
          margin-bottom: 20px;
        }
        p {
          line-height: 2;
          text-align: center;
        }
      }
    }
    .nav_lxwm_dz{
        width: 100%;
        margin-top: 50px;
    }
  }
}
</style>
